import React from "react"
import PropTypes from "prop-types"

const Resume = ({ color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      fill={color}
      x="0px"
      y="0px"
      width="430.117px"
      height="430.117px"
      viewBox="0 0 430.117 430.117"
    >
      <g>
        <path d="M 348.230469 34.796875 L 326.542969 34.796875 L 326.542969 18.421875 C 326.542969 8.25 318.292969 0 308.121094 0 L 301.425781 0 C 291.25 0 283.003906 8.25 283.003906 18.421875 L 283.003906 34.796875 L 154.132812 34.796875 L 154.132812 18.421875 C 154.132812 8.25 145.882812 0 135.707031 0 L 129.011719 0 C 118.839844 0 110.59375 8.25 110.59375 18.421875 L 110.59375 34.796875 L 81.472656 34.796875 C 54.046875 34.796875 31.738281 57.109375 31.738281 84.535156 L 31.738281 379.96875 C 31.738281 407.390625 54.046875 429.703125 81.472656 429.703125 L 348.230469 429.703125 C 375.65625 429.703125 397.964844 407.390625 397.964844 379.96875 L 397.964844 84.535156 C 397.964844 57.109375 375.652344 34.796875 348.230469 34.796875 Z M 368.015625 379.964844 C 368.015625 390.875 359.136719 399.75 348.230469 399.75 L 81.472656 399.75 C 70.5625 399.75 61.6875 390.875 61.6875 379.964844 L 61.6875 84.535156 C 61.6875 73.625 70.5625 64.75 81.472656 64.75 L 110.59375 64.75 L 110.59375 88.277344 C 110.59375 98.449219 118.839844 106.699219 129.011719 106.699219 L 135.707031 106.699219 C 145.882812 106.699219 154.132812 98.449219 154.132812 88.277344 L 154.132812 64.75 L 283.003906 64.75 L 283.003906 88.277344 C 283.003906 98.449219 291.25 106.699219 301.421875 106.699219 L 308.121094 106.699219 C 318.296875 106.699219 326.542969 98.449219 326.542969 88.277344 L 326.542969 64.75 L 348.230469 64.75 C 359.136719 64.75 368.011719 73.625 368.011719 84.53125 Z M 368.015625 379.964844 " />
        <path d="M 316.21875 147.351562 L 119.394531 147.351562 C 111.125 147.351562 104.417969 154.054688 104.417969 162.324219 C 104.417969 170.597656 111.125 177.300781 119.394531 177.300781 L 316.21875 177.300781 C 324.488281 177.300781 331.195312 170.59375 331.195312 162.324219 C 331.195312 154.054688 324.488281 147.351562 316.21875 147.351562 Z M 316.21875 147.351562 " />
        <path d="M 315.164062 206.753906 L 118.339844 206.753906 C 110.070312 206.753906 103.363281 213.457031 103.363281 221.726562 C 103.363281 230 110.070312 236.703125 118.339844 236.703125 L 315.164062 236.703125 C 323.433594 236.703125 330.140625 230 330.140625 221.726562 C 330.140625 213.457031 323.433594 206.753906 315.164062 206.753906 Z M 315.164062 206.753906 " />
        <path d="M 314.109375 266.152344 L 117.285156 266.152344 C 109.015625 266.152344 102.308594 272.859375 102.308594 281.128906 C 102.308594 289.402344 109.015625 296.105469 117.285156 296.105469 L 314.109375 296.105469 C 322.378906 296.105469 329.085938 289.402344 329.085938 281.128906 C 329.085938 272.859375 322.378906 266.152344 314.109375 266.152344 Z M 314.109375 266.152344 " />
        <path d="M 313.054688 325.554688 L 116.230469 325.554688 C 107.960938 325.554688 101.253906 332.261719 101.253906 340.53125 C 101.253906 348.800781 107.960938 355.507812 116.230469 355.507812 L 313.054688 355.507812 C 321.324219 355.507812 328.03125 348.800781 328.03125 340.53125 C 328.03125 332.261719 321.324219 325.554688 313.054688 325.554688 Z M 313.054688 325.554688 " />
      </g>
    </svg>
  )
}

Resume.propTypes = {
  color: PropTypes.string,
}

Resume.defaultProps = {
  color: "#000000",
}

export default Resume
